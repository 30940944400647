export const OB = {
  id: '333643',
  name: 'OB',
  variants: ['30616'],
  variantOpenBanking: '30616',
};

export const PPT = {
  id: '343023',
  name: 'PPT enable',
  variants: ['44284'],
  variantPPTDisabled: '44284',
};

export const CUCUTA = {
  id: '344221',
  name: 'CUCUTA enable',
  46199: ['46199'],
  cucutaEnable: '46199',
};

export const INCREASE_CLARITY = {
  id: '342751',
  name: 'Increase Clarity on Product Choice',
  variants: ['43891', '43892'],
  control: '43891',
  treatment: '43892',
};

export const PURPLE_EFFECT = {
  id: '348001',
  name: 'Efecto Morado Marketing Campaign',
  variants: ['47476', '47477'],
  control: '47476',
  treatment: '47477',
};

export const MGM_TICKET_EXPERIENCE = {
  id: '348423',
  name: 'MGM Ticket Experience Marketing',
  variants: ['48150', '48151'],
  control: '48150',
  treatment: '48151',
};

export const CDT_APPLICATION_BANNER = {
  id: '348713',
  name: 'Show CDT application banner',
  variants: ['48670', '48671'],
  control: '48670',
  treatment: '48671',
};

export const CULTURAL_HACK = {
  id: '349651',
  name: 'Cultural Hack',
  variants: ['50161', '50232', '50233'],
  control: '50161',
  treatmentA: '50232',
  treatmentB: '50233',
};

export const REDEBAN = {
  id: '349904',
  name: 'Redeban',
  variants: ['50496', '50497'],
  control: '50496',
  treatment: '50497',
};

export const CULTURAL_HACK_GOALS = {
  id: '344564',
  name: 'Cultural Hack Goals',
  variants: ['46766', '46767'],
  control: '46766',
  treatment: '46767',
};
